import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";


const InstagramConfiguration = ({ open6, setOpen6}) => {

  const token = localStorage.getItem("accesstoken");
  const userId = localStorage.getItem("UserId");

  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const [id, setId] = useState("");
  const [mailId, setMailId] = useState("");
  const [mailId1, setMailId1] = useState("");
  const [mailPassword, setMailPassword] = useState("");
  const[apikey,setApikey] = useState("")
  const[clientid,setClientId] = useState("")


  // update mail configuration

  const handleSubmitMailConfigure = (e) => {
    e.preventDefault();
    if (!id) {
      var payload = {
        Access_Token: apikey,
        FacebookPage_Id: mailId,
        InstagramPage_Id: mailPassword,
        UserId: parseInt(localStorage.getItem("UserId")),
        Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };
      axios
        .post(`${BASE_URL}/user/InstagramConfigurationCRUD/`, payload, header)
        .then((res) => {
          console.log("res", res);

          if (res?.status === 200 && res.data?.id) {

            toast.success("Created Successfully");
            
            setOpen6(false);

          } else if (mailId1 === mailId) {

            toast.warning("Already Configured");

          } else if (mailId1 != mailId) {

            toast.warning("Please Configure");

          } else {

            toast.error("Check Your Page Id ");

          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data.details || "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    } else {
      var payload = {
        id: id,
        Access_Token: apikey,
        FacebookPage_Id: mailId,
        InstagramPage_Id: mailPassword,
        Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
        UserId: parseInt(localStorage.getItem("UserId")),
        Created_By: parseInt(localStorage.getItem("UserId")),
        Updated_By: parseInt(localStorage.getItem("UserId")),
      };
      axios
        .put(`${BASE_URL}/user/InstagramConfigurationCRUD/`, payload, header)
        .then((res) => {
          console.log("res", res);
          if (res?.status === 200 && res.data?.id) {
            toast.success(" Updated Successfully");
            setOpen6(false);
          } else if (mailId1 === mailId) {
            toast.warning(" Already Configured");
          } else if (mailId1 != mailId) {
            toast.warning("Please Configure ");
          } else {
            toast.error("Check Your Page Id");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            const errorMessage = error.response.data.details || "You don't have permission to do it";
            console.log(errorMessage);
            toast.error(errorMessage, {
              position: "top-right",
              autoClose: 100,
            });
          } else {
            toast.error(`${error}`, {
              position: "top-right",
              autoClose: 100,
            });
          }
        });
    }
  };

  useEffect(async () => {
    if (userId) {
      await axios
        .get(
          `${BASE_URL}/user/InstagramConfigurationCRUD/?UserId=${userId}`,
          header
        )
        .then((res) => {
          console.log(res);
          if (res) {
            setId(res.data?.id);
            setMailId(res.data?.FacebookPage_Id);
            setMailId1(res.data?.FacebookPage_Id);
            setMailPassword(res.data?.InstagramPage_Id);
            setApikey(res.data?.Access_Token);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <>
      <Dialog open={open6} onClose={() => setOpen6(false)}>
        <form onSubmit={handleSubmitMailConfigure}>
          <Div sx={{ p: 3, width: "500px" }}>
            <Typography variant="h3">Instagram Configuration</Typography>
            <DialogContent>
              <Grid container>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Facebook PageId <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="mailId"
                      value={mailId}
                      onChange={(e) => setMailId(e.target.value)}
                      placeholder="Enter Client Id"
                      sx={{ minWidth: "350px" }}
                    />
                  </Div>
                </Grid>
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Instagram PageId <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      type={showPassword ? "text" : "password"}
                      name="mailPassword"
                      value={mailPassword}
                      onChange={(e) => setMailPassword(e.target.value)}
                      placeholder="Enter Secret Id"
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ minWidth: "300px" }}
                    />
                  </Div>
                </Grid>
             
                <Grid item>
                  <Div className="row">
                    <Typography className="input-label col-12">
                      Access Token <span className="required">*</span>
                    </Typography>
                    <TextField
                      className="input-box2 col-12 ps-2"
                      name="apikey"
                      value={apikey}
                      onChange={(e) => setApikey(e.target.value)}
                      placeholder="Enter Access Token"
                      sx={{ minWidth: "320px" }}
                    />
                  </Div>
                </Grid>
             
            
              </Grid>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              {id ? (
                // <Button
                //   type="submit"
                //   sx={[DialogBoxSaveButton, FontStyle, ButtonStyle]}
                // >
                //   Update
                // </Button>
                <CustomButton label="Update" type="submit" Icon="saveIcon" />
              ) : (
                // <Button
                //   type="submit"
                //   sx={[DialogBoxSaveButton, FontStyle, ButtonStyle]}
                // >
                //   Submit
                // </Button>
                <CustomButton label="Update" type="submit" Icon="saveIcon" />
                


              )}

              {/* <Button sx={[DialogBoxCancelButton, FontStyle, ButtonStyle]}
              onClick={()=>{
                setOpen6(false);
                
              }}>
                Cancel

              </Button> */}
            <Cancelbutton label="Cancel" onClick={() => setOpen6(false)} />

            </DialogActions>
          </Div>
        </form>
      </Dialog>
    </>
  );
};

export default InstagramConfiguration;
