import baseAxios from "axios";

const axios = baseAxios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const currentURL = window.location.href;
export const Tenantname = currentURL.split(".")[0].split("//")[1];
export const BASE_URL = `https://backend.crmfarm.app/${Tenantname}`;

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;

const apiConfig = {};
apiConfig.URL1 = "https://127.0.0.1:8000/";
apiConfig.URL = BASE_URL;

export { apiConfig };
