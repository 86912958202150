import React from 'react';
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";

const Footer = ({ sidebarOptions }) => {
    console.log("sidebarOptions", { sidebarOptions });
    return (
        <Div sx={{
            py: 2,
            mr: 0,
            px: { lg: 6, xs: 4 },
            borderTop: 2,
            // borderColor: 'divider',
            position: "fixed !important",
            width: sidebarOptions?.open ? "85% !important" : "100% !important",
            bottom: "0px !important",
            borderColor: 'divider',
            bgcolor: 'background.paper',
            zIndex: 999,
        }}>
            <Div sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant={"body1"} color={"text.primary"}>Copyright <span style={{ color: "#00BFFF" }}>CRMFARM</span> © 2022</Typography>
                <Div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Typography variant={"body1"}> Developed by @ <span style={{ color: "#00BFFF" }}>Foyer Technology</span></Typography>
                </Div>
            </Div>
        </Div>
    );
};

export default Footer;
