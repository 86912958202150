import React, { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import configureStore, { history } from "./redux/store";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import { ToastContainer, toast } from "react-toastify";
import { ContextProvider } from "./contexts/ContextProvider";
import { fetchUserRolePermissions } from "./redux/actions/fetchUserRolePermissions";
import { fetchDateFormater } from "./redux/actions/fetchDateFormater";
import { fetchCustomForms } from "./redux/actions/fetchCustomForms";
import { CRMCustomLoader } from "./pages/widgets/StylesComponents";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import VerticalDefault from "./layouts/vertical-default/VerticalDefault";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const store = configureStore();


function App() {

  const token = localStorage.getItem("accesstoken");     // added by ponkumar on 11-07-2024
  const designation = localStorage.getItem("Designation");    // added by ponkumar on 11-07-2024

  store.dispatch(fetchUserRolePermissions(token, designation));    // added by ponkumar on 11-07-2024
  store.dispatch(fetchDateFormater(token));    // added by ponkumar on 02-08-2024
  store.dispatch(fetchCustomForms(token));    // added by ponkumar on 09-08-2024

  const [isOnline, setIsOnline] = useState(navigator.onLine);


  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);


  useEffect(() => {
    if (!isOnline) {
      toast.error("please check your internet connection", { autoClose: 2000 });
    } else {
      toast.success("Internet connected", { autoClose: 2000 });
      console.log("Internet connected");
    }
  }, [isOnline]);


  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ContextProvider>
          <BrowserRouter history={history}>
            <AppProvider>
              <JumboApp activeLayout={config.defaultLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <JumboDialogProvider>
                      <AppLayout>
                        <Suspense fallback={<CRMCustomLoader />}>
                          <AppRoutes isOnline={isOnline} />

                          <ToastContainer />
                        </Suspense>
                      </AppLayout>
                    </JumboDialogProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
            </AppProvider>
          </BrowserRouter>
        </ContextProvider>
      </Provider>
    </QueryClientProvider>
  );
}
export default App;