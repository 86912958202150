import { BASE_URL } from "app/services/auth-services";
import axios from "axios";
import { BsFileRuledFill } from "react-icons/bs";

const CustomFormList = async (token) => {
  let dynamicForm = [];

  try {
    const res = await axios.get(`${BASE_URL}/user/CustomizeFormlist/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    dynamicForm = res?.data?.map((form) => ({
      id: null,
      type: "nav-item",
      title: (
        <span
          style={{
            color: form?.Is_Active ? "#4B4B4B" : "#CDCDCD",
            fontWeight: form?.Is_Active ? "bold" : "normal",
          }}
        >
          {form?.FormName}
        </span>
      ),
      icon: (
        <BsFileRuledFill
          className="sideBarIcon"
          style={{
            fontSize: 14,
            color: form?.Is_Active ? "#4B4B4B" : "#CDCDCD",
          }}
        />
      ),
      path: form?.Is_Active ? `/CFList/${form?.id}` : null,
      color: form?.Is_Active ? "#4B4B4B" : "#CDCDCD",
    }));

    return dynamicForm;
  } catch (error) {
    console.error("error:", error);
    return [];
  }
};

export default CustomFormList;