import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grow } from "@mui/material";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import navConfig from "app/layouts/shared/sidebars/Sidebar/adminsidenavmenu";
import "./LandingPage.css";
import { useStateContext } from "app/contexts/ContextProvider";
import { landingDatas } from "./LandingDatas";
import { FaRegCheckCircle } from "react-icons/fa";

const LandingPage = () => {
  const [childItems, setChildItems] = useState([]);
  const [datas, setDatas] = useState({});
  const { landingpath, setLandingpath } = useStateContext();

  const navigate = useNavigate();

  // Function to extract child items based on the current path
  const getChildItems = (path) => {
    const parentItem = navConfig?.find((item) => item?.path === path);

    if (parentItem && parentItem?.children?.length > 0) {
      return parentItem;
    }

    for (const item of navConfig) {
      const subChild = item?.children?.find((ch) => ch?.path === path);
      if (subChild && subChild?.children?.length) {
        return subChild;
      }
    }

    return [];
  };

  const type = localStorage.getItem("UserType");

  useEffect(() => {

    if (landingpath) {
      let newData = landingDatas?.find((item) => item?.id === landingpath);

      console.log("newData", newData, landingpath);
      setDatas(newData || {});
      const childItems = getChildItems(landingpath);
      setChildItems(childItems);
    } else {
      if (type === "User") {
        navigate("/home");
      } else {
        navigate("/user-admin-dashboard");
      }
    }
  }, [landingpath]);

  // Function to render child navigation links
  // const renderChildLinks = () => {
  //   return childItems?.map((item, index) => (
  //     <li
  //       onClick={() => {
  //         if (item?.children) {
  //           navigate(`/landing-page`);
  //           setLandingpath(item?.path);
  //         } else {
  //           navigate(`/${item?.path}`);
  //           setLandingpath(item?.path);
  //         }
  //       }}
  //       style={{
  //         fontSize: "16px",
  //         color: Colors?.Mblue,
  //         cursor: "pointer",
  //         margin: "10px 0px",
  //         "&:hover": { color: "#30B0C7" },
  //       }}
  //       key={index}
  //     >
  //       {item?.title}
  //     </li>
  //   ));
  // };

  return (
    <JumboContentLayoutMain>
      <section
        id="about"
        class="about section md:h-full transform md:-translate-y-10"
      >
        <div class="container h-full">
          <div class="row items-center justify-between gy-3 h-full">
            <div
              class="col sm:col-span-12 md:col-span-6 col-lg-7 content"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div>
                {/* <p class="who-we-are">Who We Are</p> */}
                <h3>{datas?.title || "-"}</h3>
                <i style={{ fontSize: "16px", marginTop: "10px", marginLeft: "10px" }}>
                  <span style={{ fontSize: "20px", fontWeight: 600 }}>"</span> {datas?.content?.subContent} <span style={{ fontSize: "20px", fontWeight: 600 }}>"</span>
                </i>
                <p className="text-end transform " style={{ fontSize: "15px", fontWeight: 600 }}>
                  - {datas?.content?.author}
                </p>
              </div>
              <Grow in>
                <div className="ul" style={{ background: "linear-gradient( to right, #f9f9f9 20%, #f2fff3 99%)" }}>
                  <div>
                    <p className="mb-2" style={{ fontSize: "18px", fontFamily: "sans-serif" }}>
                      {datas?.content?.para}
                    </p>
                    <ul>
                      {datas?.content?.points?.map((point, i) => (
                        <li
                          key={i}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "self-start",
                          }}
                        >
                          <span style={{ paddingTop: "5px" }}>
                            <FaRegCheckCircle
                              style={{ color: "#58d862", fontSize: "1.15rem" }}
                            />
                          </span>
                          <h4 className="landingpage-content">{point}</h4>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Grow>
            </div>

            <div
              class="col sm:col-span-12 md:col-span-6 col-lg-5 about-images flex items-center justify-center"
              style={{ textAlign: "center" }}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img
                src={datas?.image || ""}
                class="img-fluid"
                alt={datas?.image || ""}
                style={{
                  minWidth: "300px",
                  minHeight: "300px",
                  width: "80%",
                  // height: "80%",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </JumboContentLayoutMain>
  );
};

export default LandingPage;
