import React, { useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { BASE_URL } from "../../../services/auth-services";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";

const ForgotPassword = ({ username, setLoginPageView, setForgotPageView }) => {
  const navigate = useNavigate();
  const [UserEmail, setUserEmail] = useState("");
  const [OTPNumber, setOTPNumber] = useState("");
  const [UserOTP, setUserOTP] = useState("");
  const [ResetEmailview, setResetEmailview] = useState(true);

  const handleEmailChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleOTPChange = (event) => {
    setUserOTP(event.target.value);
  };
  const generateOTP = (length = 6) => {
    const digits = "0123456789";
    const digitsLength = digits.length;
    let OTP = Array(length)
      .fill(0)
      .map(() => digits[Math.floor(Math.random() * digitsLength)])
      .join("");
    return OTP;
  };

  const getdata = generateOTP();

  const handleReset = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/useradmin/UserCRUD_GET/?username=${username}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("handleReset response:", response);
      if (response?.data?.email === UserEmail.trim()) {
        handleGenerateOpt();
      } else {
        toast.error(
          "Your Username and give email is mismatching, Please give approcipate email to user"
        );
      }
    } catch (error) {
      console.error("Error fetching handleReset function:", error);
      toast.error(
        "The provided username and email do not match. Please enter the correct email associated with the username."
      );
    }
  };

  const handleGenerateOpt = async () => {
    try {
      var payload = {
        username: username,
        subject: "Reset Password OTP-CRMFarm",
        MailContent: `<div className="card">
      <div className="card-body">
        <h4 className="card-title">Hi,</h4>
       
        <p className="card-text">
        To reset the account password, we require you to verify your identity with a One-Time Password (OTP).
        </p>
      <br/>
<p>
      Here is your OTP: <span style="font-size: 18px; font-weight: 600;">${getdata}</span>
</p>
      <br/>
<p>
      Please enter this OTP on the Forgot page within the next 10 minutes to complete the verification process and gain access to your account.
</p>
      <br/>
<p>
      If you did not request this OTP or have any concerns about your account's security, please contact our support team immediately.
</p>
        <br/>

        <br/>
        <div> CRMFarm Team </div>
        <br/>
        <br/>
        <br/>

      </div>
    </div>`,
      };

      const respone = await axios.post(
        `${BASE_URL}/useradmin/GenerateOTP/`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("res", respone);
      console.log(getdata);
      setOTPNumber(getdata);
      setResetEmailview(false);
    } catch (error) {
      console.error("Error fetching handleGenerateOpt function:", error);

      toast.error("something went wrong");
    }
  };

  const handleSubmitOTP = async () => {
    console.log("handleSubmitOTP");
    if (OTPNumber === UserOTP) {
      console.log("Reset success");
      navigate(`auth-pages/Reset-PassWord`);
    } else {
      console.log("Reset failed");
    }
  };

  const handleNavigateToHome = () => {
    setLoginPageView(true);
    setForgotPageView(false);
    toast.success(
      "The issue has been successfully escalated to the user admin"
    );
  };

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // ml: {xs: 0, md: 60, xl: 60},
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ mb: 3, display: "inline-flex" }}>
        <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
          <img
            src="../images/CRM_Farm_Logo.png"
            width={200}
            height={45}
            alt="CRM React"
          />
        </Link>
      </Div>
      <Div sx={{ display: "inline-flex" }}>
        <Paper
          sx={{
            maxWidth: "100%",
            width: { xs: 250, md: 360 },
            mb: 4,
            p: 1,
            border: "1px solid #ededed",
            borderRadius: "10px",
          }}
        >
          <Div sx={{ position: "relative", height: "100px" }}>
            <Div
              sx={{
                flex: 1,
                inset: 0,
                position: "absolute",
                // display: "flex",
                alignItems: "center",
                backgroundColor: (theme) => alpha(theme.palette.common.white),
                p: (theme) => theme.spacing(3),
              }}
            >
              <Typography
                variant={"h2"}
                sx={{
                  color: "common.black",
                  textAlign: "center",
                  fontSize: { xs: "1.2rem", md: "1.5rem" },
                  mb: 0,
                }}
              >
                What is My Password?
              </Typography>
              <Typography
                variant={"h4"}
                sx={{
                  color: "common.black",
                  opacity: 0.7,
                  mt: 2,
                  fontSize: "0.8rem",
                  mb: 0,
                }}
              >
                If you have forgot your password you can reset it here.
              </Typography>
            </Div>
          </Div>
          <CardContent>
            {ResetEmailview ? (
              <Div>
                <Div sx={{ mb: 3, mt: 1 }}>
                  {/* <InputLabel htmlFor="email">Email</InputLabel>
                  <TextField
                    fullWidth
                    className="input-box2"
                    id="email"
                    value={UserEmail}
                    onChange={handleEmailChange} */}
                  {/* /> */}
                  <TextField
                    fullWidth
                    className="multiline-box"
                    id="email"
                    label="Email"
                    focused
                    spellCheck
                    value={UserEmail}
                    onChange={handleEmailChange}
                    placeholder="Enter Mail ID"
                  />
                </Div>

                <Div
                  sx={{ mb: 3, display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  <CustomButton
                    label="Reset Password"
                    width={"300px"}
                    Icon={false}
                    endIcon={false}
                    onClick={() => {
                      handleReset();
                    }}
                  />
                </Div>
              </Div>
            ) : (
              <Div>
                <Div sx={{ mb: 3, mt: 1 }}>
                  <TextField
                    className="multiline-box"
                    id="OTP"
                    label="Enter OTP"
                    value={UserOTP}
                    onChange={handleOTPChange}
                  />
                </Div>

                <CustomButton
                  label="Submit OTP"
                  onClick={() => {
                    handleSubmitOTP();
                  }}
                  sx={{ mb: 3, width: "100%" }}
                />
              </Div>
            )}

            <Typography textAlign={"center"} variant={"body1"} mb={1}>
              Don't remember your email?{" "}
              {/* <Link underline="none" href="#">
                Contact Support
              </Link> */}
              <Button onClick={handleNavigateToHome}>Contact Support</Button>
            </Typography>

            <Cancelbutton
              sx={{
                float: "right",
                mb: 1,
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={() => {
                setLoginPageView(true);
                setForgotPageView(false); // Change newValue to the value you want to set for the second state
              }}
            >
              Cancel
            </Cancelbutton>
          </CardContent>
        </Paper>
      </Div>
    </Div>
  );
};

export default ForgotPassword;
