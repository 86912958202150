import React from "react";
import MailIcon from "@mui/icons-material/Mail";


const mailmenu = [
  {
    id: 16,
    path: `mail/email-lists`,
    title: "Mail",
    type: "nav-item",
    icon: <MailIcon sx={{ fontSize: 18, }} />,
    color: "#4B4B4B",
  },
];

export default mailmenu;
