import React from "react";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddCardIcon from '@mui/icons-material/AddCard';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import AutorenewIcon from '@mui/icons-material/Autorenew';



const requestmenus = [
  {
    id: 9,
    title: "Request",
    path: "request",
    type: "collapsible",
    icon: <LibraryAddIcon style={{ fontSize: 18 }} />,
    color: "#4B4B4B",
    children: [
      {
        id: 19,
        path: `licence/list-licence`,
        title: "Additional Licence",
        type: "nav-item",
        icon: <AddCardIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 20,
        path: `storage/list-storage`,
        title: "Additional Storage",
        type: "nav-item",
        icon: <AddchartIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 21,
        path: `purchase/list-purchase`,
        title: "Addon Purchase",
        type: "nav-item",
        icon: <ShoppingBasketIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 22,
        path: `plan/cancel-plan`,
        title: "Cancel Plan",
        type: "nav-item",
        icon: <CancelScheduleSendIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 23,
        path: `renewal/list-renewal`,
        title: "Renewal",
        type: "nav-item",
        icon: <AutorenewIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },

    ],
  },
];
export default requestmenus;
