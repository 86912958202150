import React from "react";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";


const MessagesHeader = ({ settingMenuCallback, length }) => {
  return (
    <CardHeader
      title={"Reminders"}
      subheader={`${length} new Reminders`}
      action={
        <Stack direction="row" alignItems="center" sx={{ mr: 1 }}>
          {/* <IconButton aria-label="compose">
                        <ModeEditIcon fontSize={"small"}/>
                    </IconButton>
                    <IconButton aria-label="starred">
                        <StarBorderIcon fontSize={"small"}/>
                    </IconButton>
                    <IconButton edge={"end"} aria-label="starred" onClick={settingMenuCallback}>
                        <MoreHorizIcon fontSize={"small"}/>
                    </IconButton> */}
        </Stack>
      }
      sx={{
        "& .MuiCardHeader-action": {
          alignSelf: "center",
        },
      }}
    />
  );
};

export default MessagesHeader;
