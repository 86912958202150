import React from "react";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { FaTicketAlt } from "react-icons/fa";


const ticketmenu = [
  {
    id: 10,
    title: "Ticket",
    type: "collapsible",
    icon: <FaTicketAlt style={{ fontSize: 18 }} />,
    color: "#4B4B4B",
    children: [
      {
        id: 24,
        path: `ticket/list-ticket`,
        title: "Ticket Lists",
        type: "nav-item",
        icon: <LocalActivityIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 24,
        path: `ticket/ticket-Createdticketlist`,
        title: "Created Ticket List",
        type: "nav-item",
        icon: <LocalActivityIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 24,
        path: `ticket/ Developecreate-ticket`,
        title: "Ticket Status",
        type: "nav-item",
        icon: <LocalActivityIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 25,
        path: `ticket/ticket-Managementgroup`,
        title: "Ticket Management Group",
        type: "nav-item",
        icon: <LocalActivityIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
    ],
  },
];
export default ticketmenu;
