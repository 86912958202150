// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menus-sidebar {
    margin-top: -20px !important;
}

.menuPadding {
    padding-left: 50px;
}



.css-nle7uj-MuiStack-root {
    padding: 3px;
    border-bottom: 1px solid #ebebeb8f;
}

.css-1hy0cpb.css-1sg2p09-MuiListItemText-root {
    color: #595959 !important;
}

.css-1hy0cpb {
    color: #030303 !important;
    background-color: #fff !important;
}

.css-1sg2p09-MuiListItemText-root {
    color: #4b4b4b !important;
}

.css-1hy0cpb,
a {
    color: #000 !important;
}

.css-1hy0cpb a:focus {
    color: #000 !important;
}

.css-fyc981-MuiListItemText-root {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    padding-left: -8px;
    color: #080808 !important;
}

.css-1nnir7w-MuiDrawer-docked .MuiDrawer-paper {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.css-1f76pv0 {
    background-color: #FFFF !important;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/shared/sidebars/Sidebar/menus.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;AACtB;;;;AAIA;IACI,YAAY;IACZ,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IAIzB,iCAAiC;AAHrC;;AAMA;IACI,yBAAyB;AAC7B;;AAEA;;IAEI,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IAGI,cAAc;IACd,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,oGAAoG;AACxG;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".menus-sidebar {\n    margin-top: -20px !important;\n}\n\n.menuPadding {\n    padding-left: 50px;\n}\n\n\n\n.css-nle7uj-MuiStack-root {\n    padding: 3px;\n    border-bottom: 1px solid #ebebeb8f;\n}\n\n.css-1hy0cpb.css-1sg2p09-MuiListItemText-root {\n    color: #595959 !important;\n}\n\n.css-1hy0cpb {\n    color: #030303 !important;\n}\n\n.css-1hy0cpb {\n    background-color: #fff !important;\n}\n\n.css-1sg2p09-MuiListItemText-root {\n    color: #4b4b4b !important;\n}\n\n.css-1hy0cpb,\na {\n    color: #000 !important;\n}\n\n.css-1hy0cpb a:focus {\n    color: #000 !important;\n}\n\n.css-fyc981-MuiListItemText-root {\n    -webkit-flex: 1 1 auto;\n    -ms-flex: 1 1 auto;\n    flex: 1 1 auto;\n    min-width: 0;\n    margin-top: 4px;\n    margin-bottom: 4px;\n    font-size: 12px;\n    padding-left: -8px;\n    color: #080808 !important;\n}\n\n.css-1nnir7w-MuiDrawer-docked .MuiDrawer-paper {\n    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;\n}\n\n.css-1f76pv0 {\n    background-color: #FFFF !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
