import React from "react";
import Login from "../pages/auth-pages/login";
// import Login from "../pages/auth-pages/login/Login";
// import Login2 from "../pages/auth-pages/login2";
// import Signup1 from "../pages/auth-pages/signup1";
import Signup2 from "../pages/auth-pages/signup2";
import ForgotPassword from "../pages/auth-pages/forgot-password";
import ResetPassword from "../pages/auth-pages/reset-password";
import Page from "@jumbo/shared/Page";
import LandingPage from "app/pages/landing-page/LandingPage";

const authRoutes = [
    // {
    //     path: "/auth-pages/login-1",
    //     element: <Page component={Login1} layout={"solo-page"} />,
    // },
    {
        path: "/",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    // {
    //     path: "/user/login",
    //     element: <Page component={Login} layout={"solo-page"} />
    // },
    // {
    //     path: "/auth-pages/signup-1",
    //     element: <Page component={Signup1} layout={"solo-page"} />
    // },
    {
        path: "/auth-pages/signup-2",
        element: <Page component={Signup2} layout={"solo-page"} />
    },
    {
        path: "/auth-pages/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },
    {
        path: "/auth-pages/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} />
    }
];

export default authRoutes;



// import React, { lazy, Suspense } from "react";
// import Page from "@jumbo/shared/Page";
// import { CRMCustomLoader } from "app/pages/widgets/StylesComponents";


// const Signup2 = lazy(() => import("../pages/auth-pages/signup2"));
// const ForgotPassword = lazy(() => import("../pages/auth-pages/forgot-password"));
// const ResetPassword = lazy(() => import("../pages/auth-pages/reset-password"));
// const Login = lazy(() => import("../pages/auth-pages/login"));
// const LandingPage = lazy(() => import("app/pages/landing-page/LandingPage"));


// const authRoutes = [
//     // {
//     //     path: "/auth-pages/login-1",
//     //     element: <Page component={Login1} layout={"solo-page"} />,
//     // },
//     {
//         path: "/",
//         element: (
//             <Suspense fallback={<CRMCustomLoader />}>
//                 <Page component={LandingPage} layout={"solo-page"} />
//             </Suspense>
//         )
//     },
//     {
//         path: "/login",
//         element: (
//             <Suspense fallback={<div>...Loading</div>}>
//                 <Page component={Login} layout={"solo-page"} />
//             </Suspense>
//         )
//     },
//     // {
//     //     path: "/user/login",
//     //     element: <Page component={Login} layout={"solo-page"} />
//     // },
//     // {
//     //     path: "/auth-pages/signup-1",
//     //     element: <Page component={Signup1} layout={"solo-page"} />
//     // },
//     {
//         path: "/auth-pages/signup-2",
//         element: (
//             <Suspense fallback={<CRMCustomLoader />}>
//                 <Page component={Signup2} layout={"solo-page"} />
//             </Suspense>
//         )
//     },
//     {
//         path: "/auth-pages/forgot-password",
//         element: (
//             <Suspense fallback={<CRMCustomLoader />}>
//                 <Page component={ForgotPassword} layout={"solo-page"} />
//             </Suspense>
//         )
//     },
//     {
//         path: "/auth-pages/reset-password",
//         element: (
//             <Suspense fallback={<CRMCustomLoader />}>
//                 <Page component={ResetPassword} layout={"solo-page"} />
//             </Suspense>
//         )
//     }
// ];

// export default authRoutes;
