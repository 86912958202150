import React from "react";
import { BiTask } from "react-icons/bi";
import TaskIcon from '@mui/icons-material/Task';
import AssignmentIcon from '@mui/icons-material/Assignment';


const taskmanagementmenus = [
  {
    id: 8,
    title: "Task Management",
    path: "task-management",
    type: "collapsible",
    icon: <BiTask style={{ fontSize: 18 }} />,
    color: "#4B4B4B",
    children: [
      {
        id: 17,
        path: `task/list-task`,
        title: "List Task",
        type: "nav-item",
        icon: <AssignmentIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },
      {
        id: 18,
        path: `task/calendar-view`,
        title: "Task Calendar View",
        type: "nav-item",
        icon: <TaskIcon sx={{ fontSize: 18, }} />,
        color: "#4B4B4B",
      },

    ],
  },

];

export default taskmanagementmenus;