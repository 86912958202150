import React from "react";
import { ImHome } from "react-icons/im";

const adminhomemenu = [
  {
    path: `user-admin-dashboard`,
    title: "Home",
    type: "nav-item",
    icon: <ImHome style={{ fontSize: 18, }} />,
    color: "#4B4B4B",
  }
];

export default adminhomemenu;
